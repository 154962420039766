<script lang="ts">
  import type { Target } from "$lib/types/shared";

  interface Props {
    class?: string;
    href?: string | null;
    arrow?: boolean;
    link?: boolean;
    primary?: boolean;
    type?: "button" | "submit" | "reset";
    target?: Target;
    defaultStyle?: boolean;
    rel?: string;
    summary?: boolean;
    textStart?: boolean;
    role?: string;
    lang?: string;
    tabindex?: number;
    disabled?: boolean;
    hidden?: boolean;
    children?: import("svelte").Snippet;
    iconStart?: import("svelte").Snippet;
    onclick?: (event: MouseEvent) => void;
  }

  let {
    class: classes = "",
    href = null,
    arrow = false,
    link = false,
    primary = false,
    type = "button",
    target = null,
    defaultStyle = false,
    rel = undefined,
    summary = false,
    textStart = undefined,
    role = undefined,
    lang = undefined,
    tabindex = undefined,
    disabled = false,
    hidden = undefined,
    children,
    iconStart,
    onclick,
  }: Props = $props();
  let tag = $derived.by(() => {
    return summary ? "summary" : href ? "a" : "button";
  });
  let fallbackRole = $derived.by(() => {
    return summary ? undefined : !href ? "button" : undefined;
  });
</script>

<svelte:element
  this={tag}
  {lang}
  {tabindex}
  {hidden}
  role={role || fallbackRole}
  class="{!defaultStyle ? 'button' : ''} {classes} {primary ? 'button-primary' : ''} {link
    ? 'button-link'
    : ''}"
  class:button-arrow-end={arrow}
  class:inline-icon-start={iconStart}
  class:button-text-start={textStart}
  {...{ disabled: type === "button" && disabled ? true : undefined }}
  {...{ href }}
  {...{ rel: href && rel ? rel : undefined }}
  {...{ type: summary || href ? undefined : type }}
  {...{ target: href ? target : undefined }}
  onclick={onclick ? onclick : undefined}
>
  <span class="start-icon-text-subgrid">
    {#if iconStart}
      {@render iconStart?.()}
    {/if}

    <span>
      {#if children}
        {@render children?.()}
      {/if}
    </span>
  </span>

  {#if arrow}
    <svg
      class="arrow-icon-end"
      aria-hidden="true"
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 14L11.6 12.6L16.2 8H0V6H16.2L11.6 1.4L13 0L20 7L13 14Z" />
    </svg>
  {/if}
</svelte:element>

<style lang="postcss">
  svg.arrow-icon-end {
    transform: translateX(-0.375ch);
    transition: transform 0.375s var(--easing);
    min-width: 1em;
  }

  .button:hover svg.arrow-icon-end {
    transform: translateX(0.25ch);
  }

  :where(.inline-icon-start) > :where(.start-icon-text-subgrid) {
    display: inline-grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 1ch;
    width: 100%;
    justify-items: start;
  }

  .button-link:where(.inline-icon-start):focus > :where(.start-icon-text-subgrid) span {
    text-decoration-color: var(--focus);
    text-decoration-line: underline;
    text-decoration-thickness: 3px;
  }

  /* has start icon, but not end arrow */
  .inline-icon-start:not(.button-arrow-end) > .start-icon-text-subgrid {
    justify-content: center;
  }

  .arrow-icon-end path {
    fill: var(--arrow-fill, currentcolor);
  }
</style>
